<script setup>
import { useRuntimeConfig } from 'nuxt/app';

import { useMetaStore } from '~/stores/meta.store';

const config = useRuntimeConfig();
const metaStore = useMetaStore();
const localePath = useLocalePath()

const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();

meta.value.href_langs = {
  en: new URL(localePath('contact_us', 'en'), config.public.websiteUrl).href,
  tr: new URL(localePath('contact_us', 'tr'), config.public.websiteUrl).href,
  ru: new URL(localePath('contact_us', 'ru'), config.public.websiteUrl).href,
  fa: new URL(localePath('contact_us', 'fa'), config.public.websiteUrl).href,
  ar: new URL(localePath('contact_us', 'ar'), config.public.websiteUrl).href,
};

metaStore.setMeta(meta.value);
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.contact_us')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mt-3 mb-8">
      <h2 class="text-indigo-900 text-2xl tracking-wide text-center my-6">Office Locations</h2>
      <div class="flex justify-center flex-auto flex-wrap gap-8 locations w-3/4 mx-auto">
        <div class="overflow-hidden mb-1">
          <div class="relative group overflow-hidden">
            <img src="https://media.vartur.com/2024/1/11/1704977171647.webp?w=300&h=500" alt=""
              class="w-full h-72 object-cover rounded-xl">
            <div
              class="transform translate-y-20 ease-in-out invisible absolute bottom-0 group-hover:visible group-hover:translate-y-0 transition w-full  duration-300">
              <div
                class="flex flex-col bg-gradient-to-t from-indigo-900 to-rgb(49 46 129 / 0.5) h-20 rounded-b-xl justify-center">
                <div class="flex gap-2 justify-center">
                  <a href="https://www.google.com/maps/place/Vartur+Real+Estate/@41.1048151,28.9841308,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab427c429effd:0x36bccaf67128e60e!8m2!3d41.1048151!4d28.9867057!16s%2Fg%2F11f3xgqcgn?entry=ttu"
                    target="_blank" class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-location-dot text-indigo-900"></i>
                  </a>
                  <a href="tel:+902128030105"
                    class="w-9 h-9 bg-indigo-900 rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-phone text-white"></i>
                  </a>
                  <a href="mailto:info@vartur.com"
                    class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-envelope text-indigo-900"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="text-center text-indigo-900 text-2xl font-medium leading-tight mt-4">{{
              $t('client.address_title_istanbul') }}</div>
            <p class="text-center text-gray-500 text-sm leading-tight mt-2 ">{{
              $t('client.address_description_istanbul') }}</p>
          </div>

        </div>
        <div class="overflow-hidden mb-1">
          <div class="relative group overflow-hidden">
            <img src="https://media.vartur.com/2023/12/13/1702492286054.webp?w=300&h=500" alt=""
              class="w-full h-72 object-cover rounded-xl">
            <div
              class="transform translate-y-20 ease-in-out invisible absolute bottom-0 group-hover:visible group-hover:translate-y-0 transition-all w-full duration-300">
              <div
                class="flex flex-col bg-gradient-to-t from-indigo-900 to-rgb(49 46 129 / 0.5) h-20 rounded-b-xl justify-center">
                <div class="flex gap-2 justify-center">
                  <a href="https://www.google.com/maps/place/LIKYA+CENTURY+21/@36.6295049,29.1152643,17z/data=!3m1!4b1!4m6!3m5!1s0x14c0415ffbcca503:0x8c5b8d1a58ac3ead!8m2!3d36.6295049!4d29.1178392!16s%2Fg%2F11cjkcxv5f?entry=ttu"
                    target="_blank" class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-location-dot text-indigo-900"></i>
                  </a>
                  <a href="tel:+902526145167"
                    class="w-9 h-9 bg-indigo-900 rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-phone text-white"></i>
                  </a>
                  <a href="mailto:info@vartur.com"
                    class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-envelope text-indigo-900"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="text-center text-indigo-900 text-2xl font-medium leading-tight mt-4">{{
              $t('client.address_title_fethiye') }}</div>
            <p class="text-center text-gray-500 text-sm leading-tight mt-2 ">{{
              $t('client.address_description_fethiye') }}</p>
          </div>

        </div>
        <div class="overflow-hidden mb-1">
          <div class="relative group overflow-hidden">
            <img src="https://media.vartur.com/2022/12/16/1671222507234.jpeg?w=300&h=500" alt=""
              class="w-full h-72 object-cover rounded-xl">
            <div
              class="transform translate-y-20 ease-in-out invisible absolute bottom-0 group-hover:visible group-hover:translate-y-0 transition w-full duration-300">
              <div
                class="flex flex-col bg-gradient-to-t from-indigo-900 to-rgb(49 46 129 / 0.5) h-20 rounded-b-xl justify-center">
                <div class="flex gap-2 justify-center">
                  <a href="https://www.google.com/maps/place/VARTUR+%C2%AE+Real+Estate+Brokerage/@25.1852093,55.2782741,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f699f3972951b:0xd96eb6edab3f9cef!8m2!3d25.1852093!4d55.280849!16s%2Fg%2F11svdp2lzl?entry=ttu"
                    target="_blank" class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-location-dot text-indigo-900"></i>
                  </a>
                  <a href="tel:+971585908442"
                    class="w-9 h-9 bg-indigo-900 rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-phone text-white"></i>
                  </a>
                  <a href="mailto:info@vartur.com"
                    class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                    <i class="fa-solid fa-envelope text-indigo-900"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="text-center text-indigo-900 text-2xl font-medium leading-tight mt-4">{{
              $t('client.address_title_dubai') }}</div>
            <p class="text-center text-gray-500 text-sm leading-tight mt-2 ">{{
              $t('client.address_description_dubai') }}</p>
          </div>

        </div>
      </div>
    </div>

    <div class="px-4">
      <h3 class="text-xl font-medium text-indigo-900 text-center mb-2 mt-5">{{ $t('client.contact_us_slogan_title') }}
      </h3>
      <p class="text-gray-600 text-center mb-5 block">{{ $t('client.contact_us_slogan_description') }}</p>
      <layout-email-form></layout-email-form>

      <div class="flex flex-col mt-10 text-center justify-center">
        <i class="fa-solid fa-headphones fa-2xl my-6 text-indigo-900"></i>
        <h4 class="text-xl text-indigo-900">{{ $t('client.customer_service') }}</h4>
        <p class="text-gray-600">{{ $t('client.you_can_call_us_now') }}</p>
        <a href="tel:+902128030105" class="text-indigo-900 mt-2 font-medium">+90 (212) 803-0105</a>
      </div>
    </div>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>